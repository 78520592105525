import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
// import { Link } from 'gatsby'


import food from '../assets/images/ecommerce/food.jpg'

import bella from '../assets/images/ecommerce/bella.png'
import bulldog from '../assets/images/ecommerce/bulldog.png'
import best from '../assets/images/ecommerce/best.png'
import jcube from '../assets/images/ecommerce/jcube.png'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Food &amp; Beverage Websites</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Food &amp; Beverage Websites</h1>
                    </header>
                    <span className="image main"><img src={food} alt="food website" /></span>
                    <p>The restaurant's website is the center of its online presence. </p>
                    <p>It is the website that contains the essence of the restaurant in the virtual world, the center of all the campaigns that it carries out to promote itself on the Internet, which is, of course, the place where the customers are.</p>
                    <p>If you have a restaurant, it is essential that you have a website that makes your mouth water, both for its attractive design and useful information.</p>
                    
                </div>
            </section>

            <section>
            <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${bella})`}}>
                            <header className="major">
                                <h3>Restaurant</h3>
                            </header>
                            <a href="https://www.bellaacaciacatering.com/" className="link primary" target="_blank" rel="noopener noreferrer"></a>
                        </article>
                        <article style={{backgroundImage: `url(${bulldog})`}}>
                            <header className="major">
                                <h3>Coffee Shop</h3>
                            </header>
                            <a href="https://bulldogcoffee.square.site/" className="link primary" target="_blank" rel="noopener noreferrer"></a>
                        </article>
                        <article style={{backgroundImage: `url(${best})`}}>
                            <header className="major">
                                <h3>Cakes</h3>

                            </header>
                            <a href="https://bestcakes.ca" className="link primary" target="_blank" rel="noopener noreferrer"></a>
                        </article>
                        <article style={{backgroundImage: `url(${jcube})`}}>
                            <header className="major">
                                <h3>African Cousine </h3>    
                                {/* <p>Restaurant, Food Delivery &amp; more!</p>       */}
                            </header>
                            <a href="https://jcube-restaurant.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></a>
                        </article>
                       
                 </section>
            </section>
        </div>

    </Layout>
)

export default Generic